<template>
  <BaseLayout>
    <template #header>
      <h1>Penerimaan Labu Darah</h1>

      <section id="filter-section" class="p-3 rounded">
        <b-row class="align-items-center mb-3">
          <b-col cols="6">
            <p class="hp-p1-body">Asal MU : {{ $route.params.mu }}</p>
          </b-col>
          <b-col cols="6">
            <p class="hp-p1-body">Tanggal : {{ $route.params.date }}</p>
          </b-col>
        </b-row>

        <b-form-group label="Masukkan Barcode" label-for="start-date">
          <b-form-input
            id="barcode-input"
            type="text"
            v-model="form.barcode"
            @input="checkBarcodeDebounced"
          />
        </b-form-group>
      </section>
    </template>
    <template #content>
      <b-row class="d-flex justify-content-center">
        <b-col cols="auto">
          <div class="table-wrapper w-100 mx-auto">
            <b-table
              class="table-borderless table-responsive w-100"
              :fields="fields"
              :items="items"
            >
              <template #cell(no)="{ index }">
                {{ index + 1 }}
              </template>
              <template #cell(blood_type)="data">
                {{
                  data.item.blood_type.replace("+", " Pos").replace("-", " Neg")
                }}
              </template>
              <template #cell(blood_received)="{ item }">
                <b-form-checkbox
                  v-model="item.blood_received"
                  :disabled="true"
                ></b-form-checkbox>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mt-3 mb-3"
          @click="handleSubmit"
          :disabled="isLoading || !items.some((item) => item.blood_received)"
        >
          Simpan
        </b-button>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BTable,
  BFormCheckbox,
  BForm,
} from "bootstrap-vue";
import manageBlooodDelivery from "../../../../../api/blood_shipment/manageBloodShipmentAPI";
import { debounce } from "lodash";

export default {
  name: "AddBloodReceived",
  components: {
    BFormSelect,
    BFormInput,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    BTable,
    BFormCheckbox,
    BForm,
  },
  data() {
    return {
      divisionsMode: null,
      isLoading: false,
      form: {
        barcode: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
          sortable: true,
        },
        {
          key: "barcode",
          label: "Barcode",
          sortable: true,
        },
        {
          key: "blood_bag_number",
          label: "Nomor Selang",
          sortable: true,
        },
        {
          key: "blood_type",
          label: "Golongan Darah",
        },
        {
          key: "bag_type",
          label: "Jenis Kantong",
        },
        {
          key: "blood_received",
          label: "Diterima",
        },
      ],
      items: [],
      checkBarcodeDebounced: null,
    };
  },
  created() {
    this.checkBarcodeDebounced = debounce(this.checkBarcode, 1000);
  },
  methods: {
    async fetchData() {
      const modeAPIs = {
        processing: manageBlooodDelivery.getDeliveryProcessing,
        IMLTD: manageBlooodDelivery.getDeliveryIMLTD,
        KGD: manageBlooodDelivery.getDeliveryKGD,
      };

      const apiMethod = modeAPIs[this.divisionsMode];
      if (apiMethod) {
        const { data: response } = await apiMethod({
          date: this.$route.params.date,
          mu_name: this.$route.params.mu,
        });

        this.items = response.data.map((item) => ({
          ...item,
          blood_received: item.blood_received === 1,
        }));
      }
    },
    checkBarcode() {
      const barcodeToCheck = this.form.barcode;

      const matchingIndex = this.items.findIndex(
        (item) => item.barcode === barcodeToCheck
      );

      if (matchingIndex !== -1) {
        const updatedItem = {
          ...this.items[matchingIndex],
          blood_received: true,
        };

        this.$set(this.items, matchingIndex, updatedItem);
        this.$bvToast.toast("Barcode ditemukan", {
          title: "Berhasil cek barcode",
          variant: "success",
          solid: true,
        });
        this.$nextTick(() => {
          this.form.barcode = ""; // Setelah toast selesai, kosongkan input
        });
      } else {
        this.$bvToast.toast("Barcode tidak ditemukan", {
          title: "Gagal cek barcode",
          variant: "danger",
          solid: true,
        });
        this.$nextTick(() => {
          this.form.barcode = ""; // Kosongkan input setelah toast selesai
        });
      }
    },
    async handleSubmit() {
      this.isLoading = true;
      try {
        const barcode = this.items
          .filter((item) => item.blood_received)
          .map((item) => item.barcode);

        const modeAPIs = {
          processing: manageBlooodDelivery.addReceivedProcessing,
          IMLTD: manageBlooodDelivery.addReceivedIMLTD,
          KGD: manageBlooodDelivery.addReceivedKGD,
        };

        const apiMethod = modeAPIs[this.divisionsMode];
        if (apiMethod) {
          await apiMethod({ barcodes: barcode });
        }
        this.$bvToast.toast("Data berhasil diterima", {
          title: "Penerimaan Berhasil",
          variant: "success",
          solid: true,
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, 2000);
      } catch (error) {
        this.$bvToast.toast("Data gagal ditambahkan", {
          title: "Gagal menembahkan data",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    setInitialDivisionsMode() {
      if (this.$route.path.includes("processing")) {
        this.divisionsMode = "processing";
      } else if (this.$route.path.includes("IMLTD")) {
        this.divisionsMode = "IMLTD";
      } else if (this.$route.path.includes("KGD")) {
        this.divisionsMode = "KGD";
      }
    },
  },
  mounted() {
    this.setInitialDivisionsMode();
    this.fetchData();
  },
  watch: {
    $route() {
      this.setInitialDivisionsMode();
    },
  },
};
</script>
